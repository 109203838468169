import "pinia"
import { PiniaPluginContext } from "pinia"
import {
  getQueryParams,
  maybeCastArray,
  maybeCastBoolean,
  maybeCastNumber,
  updateQuery,
} from "@evercam/shared/utils"
import { camelizeKeys } from "humps"
import { useNuxtApp } from "#app"

declare module "pinia" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    syncWithUrl?: Array<keyof S>
  }
}

export function PiniaPluginSyncUrlParams(context: PiniaPluginContext): void {
  const { store, options } = context
  let isInitialized = false
  let previousStore = {} as any

  const syncWithUrl = options.syncWithUrl as string[]
  if (syncWithUrl && syncWithUrl.length > 0) {
    const initStoreFromUrl = () => {
      const query = camelizeKeys(getQueryParams())
      syncWithUrl.forEach((prop: string) => {
        if (query && query[prop] !== undefined) {
          const stateValue = query[prop]
          const initialStateValue = previousStore[prop]
          store[prop] = maybeCastArray(
            maybeCastNumber(maybeCastBoolean(stateValue)),
            Array.isArray(initialStateValue)
          )
        }
      })
    }

    const updateUrlFromStore = () => {
      const fullPath = useNuxtApp().vue2App?.$route?.fullPath
      const ignoredPaths = ["/v2/users/signin", "/v2/users/signup"]
      if (ignoredPaths?.some((path) => fullPath?.includes(path))) {
        return
      }

      const queryParams: Record<string, any> = camelizeKeys(getQueryParams())
      syncWithUrl.forEach((prop: string) => {
        const shouldUpdateUrl =
          store[prop] !== previousStore[prop] || prop in queryParams

        if (shouldUpdateUrl && store[prop] !== undefined) {
          queryParams[prop] = store[prop]
        }
      })

      updateQuery(queryParams)
      previousStore = { ...store.$state }
    }

    initStoreFromUrl()

    store.$subscribe(
      () => {
        if (!isInitialized) {
          isInitialized = true
          initStoreFromUrl()

          return
        }
        updateUrlFromStore()
      },
      { detached: true }
    )
  }
}
