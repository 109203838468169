import { UnitSystem } from "@evercam/shared/types"

export default (mainStore) => (app) => {
  const units = {
    formatDistance(distance: number, unitSystem: UnitSystem) {
      if (unitSystem === UnitSystem.Metric) {
        if (distance >= 1000) {
          return (distance / 1000.0).toFixed(2) + " km"
        } else if (distance >= 100) {
          return Math.round(distance) + " m"
        } else {
          return distance.toFixed(distance < 10 ? 2 : 1) + " m"
        }
      } else if (unitSystem === UnitSystem.Imperial) {
        const feetDecimal = 3.281 * distance
        const feetRounded = Math.floor(feetDecimal)
        const inches = Math.floor((feetDecimal - feetRounded) * 12)

        return `${feetRounded}' ${inches}"`
      } else {
        return `${distance.toFixed(1)} m`
      }
    },

    formatArea(area: number, unitSystem: UnitSystem) {
      if (unitSystem === UnitSystem.Metric) {
        if (area >= 100000) {
          return (area / 1000000.0).toFixed(2) + " km²"
        }

        return area.toFixed(2) + " m²"
      } else if (unitSystem === UnitSystem.Imperial) {
        if (area > 10000) {
          area = area / 1000000.0

          return (0.3861 * area).toFixed(2) + " mi²"
        }

        return (area * 10.76391042).toFixed(2) + " ft²"
      }

      return area.toFixed(2) + " m²"
    },

    formatVolume(volume: number, unitSystem: UnitSystem) {
      if (unitSystem === UnitSystem.Metric) {
        if (volume >= 1000000000) {
          return (volume / 1000000000.0).toFixed(2) + " km³"
        }

        return volume.toFixed(2) + " m³"
      } else if (unitSystem === UnitSystem.Imperial) {
        if (volume >= 4168181825.4406) {
          return (volume / 4168181825.4406).toFixed(2) + " mi³"
        }

        return (volume * 35.3147).toFixed(2) + " ft³"
      }

      return volume.toFixed(2) + " m³"
    },

    kelvinToCelsius(degrees: number) {
      return degrees - 273.15
    },
    kelvinToFahrenheit(degrees: number) {
      return (degrees - 273.15) * 1.8 + 32
    },
    celsiusToFahrenheit(degrees: number) {
      return degrees * (9 / 5) + 32
    },
    fahrenheitToCelsius(degrees: number) {
      return (degrees - 32) * (5 / 9)
    },
    kmhToMilesPerHour(kmh: number) {
      return kmh * 0.621371
    },
    mpsTokmh(mps: number) {
      return mps * 3.6
    },
    selectUnits(unit: UnitSystem) {
      if (mainStore) {
        mainStore().unitSystem = unit
      }

      localStorage?.setItem("localUnitSystem", unit)
      if (unit === UnitSystem.Metric) {
        app.nuxt2Context.$analytics?.saveEvent("measuring_unit_metric")
      } else if (unit === UnitSystem.Imperial) {
        app.nuxt2Context.$analytics?.saveEvent("measuring_unit_imperial")
      }
    },
    metersToFeetAndInches(m: number) {
      const feetDecimal = 3.281 * m
      const feetRounded = Math.floor(feetDecimal)
      const inches = Math.floor((feetDecimal - feetRounded) * 12)

      return `${feetRounded}${app.nuxt2Context.i18n.t(
        "content.units.foot"
      )} ${inches}${app.nuxt2Context.i18n.t("content.units.inch")}`
    },
    mmToInches(mm: number) {
      return mm / 25.4
    },
    formatBytes(bytes: number, decimals = 2) {
      if (bytes === 0) return "0 Bytes"
      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    },
    formatPercentage(value: number) {
      return `${parseFloat(value.toFixed(2))} %`
    },
    formatBitsPerSecond(bps: number, decimals = 2): string {
      if (bps === 0) return "0 b/s"
      const k = 1000
      const sizes = ["b/s", "Kb/s", "Mb/s", "Gb/s", "Tb/s", "Pb/s"]
      const i = Math.floor(Math.log(bps) / Math.log(k))

      return `${parseFloat((bps / Math.pow(k, i)).toFixed(decimals))} ${
        sizes[i]
      }`
    },
    formatTemperature(
      temperature: number,
      unitSystem: UnitSystem = UnitSystem.Metric
    ) {
      const unitChar = unitSystem === UnitSystem.Metric ? "C" : "F"

      return `${temperature.toFixed(1)} °${unitChar}`
    },
    formatVoltage(voltage: number) {
      return `${voltage}V`
    },
    formatPower(power: number) {
      return `${power}W`
    },
    formatCurrent(power: number) {
      return `${power}mA`
    },
    metric: {
      temperature: "°C",
      speed: "Km/h",
    },
    imperial: {
      temperature: "°F",
      speed: "mph",
    },
  }

  app.provide("units", units)
}
